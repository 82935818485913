
.pieChartContainer {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    // max-width: 350px;
    margin-right: 2%;
    padding: 1%;
    width: 100%;
    height: auto;

    .pieChartTitle{
        font-size: 14px;
        text-align: start;
        margin: 0px;
        padding: 5px; 
    }

    .pieChartFigures{
        font-size: 10px;
        text-align: start;
        margin: 10px;
        padding: 10px; 
    }
}