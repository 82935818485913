.topLevel {
    // padding: 20px;
    display: flex;
    gap: 20px;
    // width: 100%;

    .leftContent { 
      flex: 1;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      position: relative;
      width: 100%;

    //   .editButton {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     padding: 5px;
    //     font-size: 12px;
    //     color: #7451f8;
    //     background-color: #7551f818;
    //     cursor: pointer;
    //     border-radius: 0px 0px 0px 5px;
    //   }

      .itemContents {
        display: flex;
        gap: 20px;

        .itemContentsImg {
          width: 120px;
          height: 120px;
          border-radius: 10%;
          object-fit: cover;
          margin: 20px;
          padding: 10px;
          border: solid #555;
        }

        .detailsContents {
            margin-top: 10px;
            align-items: center;
            justify-content: center;

          .itemTitleContents {
            margin-bottom: 10px;
            color: #555;
          }

          .detailItemContents {
            margin-bottom: 10px;
            font-size: 14px;

            .itemKeyContents {
              font-weight: bold;
              color: gray;
              margin-right: 5px;
            }

            .itemValueContents {
              font-weight: 300;
            }
          }
        }
      }
    }
}