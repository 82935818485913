.sectionHeader{
    margin-top: 30px;
    margin-left: 0px;
    padding: 0px; 

    .sectionTitle{
        font-size: 20px;
        text-align: start;
        margin: 0px;
        padding: 0px; 
        text-decoration: none;
        color: black;
    }
    a:hover {
        color: rgba(0, 99, 155, 1);
    }
}