.home {
  display: flex;

  .homeContainer {
    flex: 5.5;
    margin-top: 20px;
    margin-bottom: 20px;

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }

    .dashboardPieChartContainer {
      display: flex;
      width: 100%;
      margin: 0%;
      padding: 1%;
    }

    .cardPadding{
      padding: 10px;
      display: flex;
      width: 100%;
      margin: 0px 0px 0px 0px;
    }
  }
}

.itemImg {
  width: 90px;
  height: 60px;
  border-radius: 2%;
  object-fit: contain;
  margin: 0px;
  padding: 5px;
}