.progressBarBody {
    /* Add shadows to create the "card" effect */
    padding: 1%;
    margin: 1%;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    align-items: center;
    
    .progressBarListTitle{
        font-size: 14px;
        text-align: start;
        margin-top: 1%;
        margin-bottom: 5px;
        color: #222424;
    }

    .progressBarTitle {
        font-size: 20px;
        text-align: start;
    }

    .progressBarScore {
        font-size: 20px;
        text-align: end;
    }

    .progressBar{
        padding: 0px;
        display: flex;
        width: 100%;
        // background-color: black;
    }

}
