.cardBodyContent {
    /* Add shadows to create the "card" effect */
    padding: 1%;
    // margin: 1%;
    height: 180px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    align-items: center;

    .cardBodyContentTitle {
        font-size: 20px;
        text-align: center;
    }

    .cardBodyContentDetail{
        margin-left: 20px;
        font-size: 40px;
        text-align: center;
        margin-top: 3%;
        color: #4EC7F2;
    }

    .imageBodyContainer{
        
    }

    .image{
        text-align: center;
        margin-top: 10%;
        color: #4EC7F2;
        height: 60px;
        width: 60px;
    }

    .cardTwoContents{
        margin-top: 0%;
        justify-content: center;
        align-items: center;
        padding: 2%;
        display: flex;
    }
  }