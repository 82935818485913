p, div {
    font-family: Lato;
  }
    
  .wrapper {
    height: 97vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(10, 130, 199);
  }

  .wrapperSignup {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 244, 246);
  }
  
  .form-wrapper {
    display: flex;
    flex-direction: column;
    width: 380px;
    max-width: 80%;
    min-width: 100px;
    min-height: 400px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
  }
  
  .form-wrapper > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: #222;
  }
  
  form {
    width: 100%;
    display: block;
    flex-wrap: wrap;
  }  
  
  label {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
  }
  
  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  
  button {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 0.5em;
    padding:  0.938em;
    border: none;
    border-radius: 4px;
    background-color: rgb(22, 55, 165);
    color: #fefefe;
  }
  button:hover {
    background-color: rgb(7, 141, 195);
    color: #fefefe;
  }
   
  .fullName,
  .username,
  .email,
  .password {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }
   
  .submit {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .hasAccount{
    font-size: 20px;
    display: flex;
  }

  .login{
    font-size: 20px;
    margin-left: 10px;
  }

.forgotpass{
  font-size: 20px;
  margin-left: 10px;
  color: red;
}

.image{
  text-align: center;
  margin-top: 0%;
  color: #4EC7F2;
  width: 260px;
  height: 120px;
  border-radius: 5%;
}