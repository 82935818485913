.cardNavBody {
    /* Add shadows to create the "card" effect */
    display: flex;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10%;
    // margin-left: 5%;
    margin-top: 1%;
    margin-bottom: 1%;
    height: 60px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    align-items: center;
    justify-content: space-between;

    .cardNavContentTitle {
        margin-left: 10px;
        font-size: 14px;
        text-align: center;
    }

    .cardNavContent{
        font-size: 64px;
        text-align: center;
        margin-top: 0%;
        color: #4EC7F2;
    }

    .imageContainer{
        
    }

    .image{
        text-align: center;
        margin-top: 0%;
        color: #4EC7F2;
        width: 30px;
        height: 30px;
        border-radius: 5%;
    }

    .imageRightArrow{
        text-align: center;
        margin-top: 5px;
        margin-left: 30px;
        color: #4EC7F2;
        width: 20px;
        height: 20px;
        border-radius: 5%;
    }
    
  }