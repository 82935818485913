body{
    margin-top:20px;
    color: #1a202c;
    text-align: left;
    background-color: #e2e8f0;    
}
.main-body {
    padding: 20px;
    font-size: large;
    font-weight: 500;
  
    
    .row {
        display: flex;
        padding: 20px;
        // flex-direction: column;

        .col-md-4 {
            width: 40%;
            border: 0 solid rgba(0, 0, 0, 0.125);
            margin: 1%;

            .card {
                padding: 20px;
            }
        }

        .col-md-8 {
            width: 60%;
            margin: 1%;


            .card {
                padding: 20px;
                // margin: 1%;


                .row {
                    display: flex;

                    .col-sm-3 {
                        width: 30%;
                    }

                    .col-sm-9 {
                        width: 70%;
                    }
                }
            }
        }
    }
}

.list-group {
    display: block;
    list-style: none;

    .list-group-item {
        display: flex;
        margin: 1%;
        padding: 1%;
        // justify-content:space-evenly;
        .mb-0 {
            width: 30%;
        }
        .text-secondary {
            width: 70%;
            span {
                display: flex;
            }
        }
    }
}

.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
    margin-top: 2%;

}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}